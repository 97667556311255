import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

declare global {
  interface IDBRequest<T = any> {
    wait() : Promise<T>;
  }
}

IDBRequest.prototype.wait = function<T>() {
  return new Promise<T>((resolve, reject) => {
    this.onsuccess = function() {
      resolve(this.result);
    };
    this.onerror = function() {
      reject(this.error);
    };
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
