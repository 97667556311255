import React, { useEffect, useState } from 'react';
import logo from './logo.svg';

interface IInfo {
  number: number;
  recent: string;
}

async function dbOpen() {
  const dbRequest = indexedDB.open(dbOpen.name, 1);

  dbRequest.onupgradeneeded = function() {
    const journal = dbRequest.result.createObjectStore(dbOpen.stores.journal, {
      keyPath: 'key'
    });
    journal.createIndex('date', 'date')
  };

  return await dbRequest.wait();
}

dbOpen.name = 'guestbook';
dbOpen.stores = {
  journal: 'guestbook-journal'
};

function App() {
  const [info, setInfo] = useState<IInfo>({
    number: 0,
    recent: 'never',
  });

  const fn = async function() {
    const db = await dbOpen();

    const transaction = db.transaction(dbOpen.stores.journal, 'readonly');

    const table = transaction.objectStore(dbOpen.stores.journal);
    const index = table.index('date');

    const countRequest = index.count();
    const fetchRequest = index.openCursor(null, 'prev');

    const count = await countRequest.wait();
    const first = await fetchRequest.wait();
    
    setInfo({
      number: count,
      recent: first?.value?.date?.toUTCString() || 'never',
    })
  };

  useEffect(() => {
    fn();
  }, []);

  const fireClick = async () => {
    const db = await dbOpen();

    const transaction = db.transaction(dbOpen.stores.journal, 'readwrite');

    const table = transaction.objectStore(dbOpen.stores.journal);

    table.add({
      key: crypto.randomUUID(),
      date: new Date(),
    })

    transaction.commit();

    await fn();
  };

  return (
    <div className='h-full w-full'>
      <div className="h-full w-full flex justify-center items-center">
        <div className="block p-6 rounded-lg shadow-lg bg-white lg:w-1/3 w-11/12">
          <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">IndexDB Test</h5>
          <p className="text-gray-700 text-base mb-4 whitespace-normal">
            This page stores the current date inside our indexdb. All you have to do is press the button below.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Number of items: <span className='font-medium'>{info.number}</span>
            <br></br>
            <span className='font-medium'>{info.recent}</span>
          </p>
          <button type="button" onClick={fireClick} className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Store Date</button>
        </div>
      </div>
    </div>
  );
}

export default App;
